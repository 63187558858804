
import { computed, defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown from "@/components/Dropdown.vue";

import { usePagination } from "@/libs/utils";
import { useDropdownOptions } from "@/views/agenda/mixins";

const VAgendaConsultation = defineComponent({
  name: "VAgendaConsultation",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown },
  setup() {
    useHead({ title: "Lista de consultas na agenda | obmed" });
    const { getDropdownOptions } = useDropdownOptions();

    const store = useStore();
    const agendas = computed(() => store.state.agenda.list);
    const loading = reactive({ list: false });

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "agenda",
      setPage: "setAgendaPaginationPage",
      updateList: getAgendas,
    });

    async function getAgendas() {
      loading.list = true;
      await store.dispatch("getAgendas", { ie_tipo_agenda: "C" });
      loading.list = false;
    }

    getAgendas();

    return { agendas, loading, pagination, getAgendas, getDropdownOptions, handleUpdateCurrentPage };
  },
});

export default VAgendaConsultation;
